module.exports = {
  siteTitle: 'Compagnon Kératocône',
  siteDescription: "👋 Compagnon pour la vue, Compagnon pour la vie !",
  authorName: 'Papa',
  twitterUsername: '_PapaSall',
  authorAvatar: '/images/avatar.png',
  multilangPosts: true, // enable/disable flags in post lists
  authorDescription: `
  `,
  siteUrl: 'https://dev.compagnonkeratocone.fr/',
  disqusSiteUrl: 'https://dev.compagnonkeratocone.fr/',
  // Prefixes all links. For cases when deployed to alphasall.com/ck/
  pathPrefix: '', // Note: it must *not* have a trailing slash.
  siteCover: '/images/cover.jpg',
  googleAnalyticsId: 'UA-67868977-1',
  background_color: '#ffffff',
  theme_color: '#222222',
  display: 'minimal-ui',
  icon: 'static/images/ck-icon.png',
  disqusShortname: 'p4ch0u',
  headerLinks: [
    {
      label: '🏡',
      url: '/',
    },
    {
      label: 'Nous soutenir',
      url: '/soutien',
    },
    {
      label: 'Le keratocone',
      url: '/le-keratocone',
    },
    {
      label: 'Nos actions',
      url: '/actions',
    },
  ],
  // Footer information (ex: Github, Netlify...)
  websiteHost: {
    name: 'Alpha',
    url: 'https://alphasall.com',
  },
  footerLinks: [
    [
      'Explore',
      {
        label: 'Blog',
        url: '/blog',
      },
    ],
    [
      'Follow the author',
      {
        label: 'Github',
        url: 'https://github.com/p4ch0u',
        iconClassName: 'fa fa-github',
      },
      {
        label: 'Website',
        url: 'https://alphasall.com',
        iconClassName: 'fa fa-globe',
      },
      {
        label: 'Twitter',
        url: 'https://twitter.com/_PapaSall',
        iconClassName: 'fa fa-twitter',
      },
    ],
  ],
}
